import { apiCheckEquity } from '@/resource'

export default {
  data() {
    return {
      positionDialog: {
        show: false,
        text: '',
        type: '',
        showConfirm: false,
        showCancel: false,
      },
    }
  },
  methods: {
    async checkPosition(account, amount, i18n, callback) {
      const resp = await apiCheckEquity({ mt4Account: account, amount: amount })
      if (resp.data.code === 0) {
        if (resp.data.data === 'Stop') {
          this.positionDialog = {
            show: true,
            text: this.$t(`${i18n}.default.equityStop`),
            type: 'Stop',
            showConfirm: false,
            showCancel: true,
          }
        } else if (resp.data.data === 'Margin') {
          this.positionDialog = {
            show: true,
            text: this.$t(`${i18n}.default.equityMargin`),
            type: 'Margin',
            showConfirm: false,
            showCancel: true,
          }
        } else if (resp.data.data === 'Warning') {
          this.positionDialog = {
            show: true,
            text: this.$t(`${i18n}.default.equityContinue`),
            type: 'Warning',
            showConfirm: true,
            showCancel: true,
          }
        } else if (resp.data.data === 'Pass') {
          callback()
        }
      }
    },
    hidePositionDialog() {
      this.positionDialog.show = false
    },
    equityConfirm(callback) {
      this.hidePositionDialog()
      callback()
    },
  },
}
