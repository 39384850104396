<template>
  <div class="promotion">
    <DialogVersion3 :visible="isVisible" visibleFooter hideChat @close="onClose">
      <div class="top">
        <div class="header-text">{{ $t('promotion.header') }}</div>
        {{ mainMessage }}
      </div>
      <div class="tnc">
        <el-form :model="form" :rules="rule" :show-message="false" ref="Form">
          <el-form-item prop="checked">
            <el-checkbox v-model="form.checked" data-testid="tncConfirm">
              <i18n path="openAdditAcc.byTicking" tag="div">
                <template v-slot:tnc>{{ $t('openAdditAcc.tnc') }} </template>
              </i18n>
            </el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-box">
        <div class="btn-box">
          <el-button
            class="btn-default"
            @click="onAgreeAction"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="agreeAction"
          >
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button
            class="btn-blue"
            @click="onClose"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="disagreeAction"
          >
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { checkAgreedTNC } from '@/util/validation';

export default {
  props: {
    isVisible: Boolean,
    mainMessage: String
  },
  components: { DialogVersion3 },
  data() {
    return {
      form: {
        checked: false
      },
      rule: {
        checked: [{ validator: checkAgreedTNC, trigger: 'change' }]
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    onClose() {
      this.$emit('update:isVisible', false);
    },
    onAgreeAction() {
      this.$refs['Form'].validate(valid => {
        if (valid) {
          this.$emit('onConfirm');
          this.$emit('update:isVisible', false);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/promotion.scss';
</style>
