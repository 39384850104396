export function getObjectValue(obj, keys, fallback = null){
  if (!obj || !keys )return fallback;
  
  if (typeof obj !== 'object') return fallback;

  if (typeof keys === 'string') {
    keys = keys.split('.');
  }

  for (let i=0; obj && i < keys.length; i++){
    if(!obj.hasOwnProperty(keys[i])) {
      return fallback
    }
    obj = obj[keys[i]];
  }

  if (obj === null || typeof obj === 'undefined') {
    return fallback;
  }

  return obj
}