import { PROMOTION_TYPE } from "@/constants/promotion"
import { apiCampaignInfo } from "@/resource"

export default {
  data() {
    return {
      tradingBonus: {
        checkVisible: false,
      },
    }
  },
  computed: {
    tradingBonusCampaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.TRADING_BONUS) || {}
      if (!campaign.id) {
        return null
      }
      return campaign.id
    },
  },
  methods: {
    async getActiveTradingBonusAccount(){
      if (this.tradingBonusCampaignID) {
        return apiCampaignInfo(this.tradingBonusCampaignID).then(res => {
          return res.data.data.mt4Account
        })
      }
    },
  },
}
