<template>
  <DefaultTemplate :showStep="flag">
    <div class="transferFunds-box" v-if="flag">
      <div class="form-left">
        <h3>{{ $t('menu.transferBetweenAccs') }}</h3>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <div class="info" v-if="false">
            We are undergoing system maintenance between 00:30-02:30 (GMT+3) , 8 May. During this time all transfers
            between accounts will be processed manually, and might be subject to slight delays. Please do not submit
            transfer requests multiple times. We apologise for any inconvenience caused.
          </div>
          <ul class="form-list clearfix">
            <li>
              <p>{{ $t('common.key.from') }}</p>
              <el-form-item :label="$t('common.field.accNum')" prop="fromAccount">
                <el-select v-model="selected.fromAccount" data-testid="fromAccount">
                  <el-option
                    v-for="item in formAccountOptions"
                    :key="item.value"
                    :value="item.login + ' - ' + item.currency"
                    :label="getCurrencySymbol(item)"
                    :data-testid="item.login"
                    :disabled="item.balance < 0"
                  >
                    <template v-if="item.login == tradingContestAccNo">
                      <div>
                        {{ getCurrencySymbol(item) }}
                        <span class="ml-2"><TrophyIcon /></span>
                      </div>
                    </template>
                  </el-option>
                </el-select>
                <img class="link" src="@/assets/images/transferFunds/link.png" alt="" />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.amt')" prop="amount">
                <numeric-input
                  v-model="ruleForm.amount"
                  :currency="selected.currentCurrency"
                  :precision="2"
                ></numeric-input>
              </el-form-item>
            </li>
            <li>
              <p>{{ $t('common.key.to') }}</p>
              <el-form-item :label="$t('common.field.accNumTo')" prop="toAccount">
                <el-select v-model="selected.toAccount" data-testid="toAccount">
                  <el-option
                    v-for="item in toAccountOptions"
                    :key="item.value"
                    :value="item.login + ' - ' + item.currency"
                    :label="getCurrencySymbol(item)"
                    :data-testid="item.login"
                  >
                    <template v-if="item.login == tradingContestAccNo">
                      <div>
                        {{ getCurrencySymbol(item) }}
                        <span class="ml-2"><TrophyIcon /></span>
                      </div>
                    </template>
                  </el-option>
                </el-select>
              </el-form-item>
            </li>
            <li v-if="currentCurrency && selected.currentCurrency !== currentCurrency">
              <el-form-item :label="$t('common.field.amtIn') + ` ${currentCurrency}`" prop="convertedAmt">
                <el-input
                  v-model="amountTo"
                  id="convertedAmt"
                  auto-complete="new-password"
                  data-testid="convertedAmt"
                  disabled
                />
              </el-form-item>
            </li>
          </ul>
          <div class="info-box">
            <span>{{ $t('transferFunds.pleaseNote') }}</span>
            <br /><br />
            <p>{{ $t('transferFunds.warn1') }}</p>
            <br />
            <p>{{ $t('transferFunds.warn3') }}</p>
          </div>
          <el-form-item>
            <el-button class="btn-red" @click="submitForm('ruleForm')" data-testid="submit">
              {{ $t('common.button.submit') }}
            </el-button>
          </el-form-item>
        </el-form>
        <InteractiveDialog :show.sync="dialog.show" :text="dialog.text">
          <template v-slot:header>
            <img src="@/assets/images/dialog_info.png" class="info-icon" alt="info-icon" />
          </template>
          <template v-slot:body v-if="dialog.type === 'BLACKLISTED'">
            <i18n path="transfer.blackListWarn" tag="p">
              <template v-slot:mailTo>
                <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
              </template>
            </i18n>
          </template>
          <template v-slot:btn>
            <el-button class="btn-default" @click="dialog.show = false" data-testid="confirm">
              {{ $t('common.button.confirm') }}
            </el-button>
          </template>
        </InteractiveDialog>
        <InteractiveDialog :show.sync="positionDialog.show" :text="positionDialog.text">
          <template v-slot:btn>
            <el-button
              class="btn-default"
              v-if="positionDialog.showCancel"
              @click="positionDialog.show = false"
              data-testid="cancel"
            >
              {{ $t('common.button.cancel') }}
            </el-button>
            <el-button
              class="btn-blue"
              v-if="positionDialog.showConfirm"
              @click="equityConfirm(applyTransfer)"
              data-testid="confirm"
            >
              {{ $t('common.button.confirm') }}
            </el-button>
          </template>
        </InteractiveDialog>
      </div>
      <div class="form-right">
        <img class="" src="@/assets/images/transferFunds/icon.png" alt />
      </div>
    </div>
    <Result v-else>{{ $t('transferFunds.suc') }}</Result>
    <CheckDialog
      :isVisible.sync="tradingBonus.checkVisible"
      @onConfirm="applyTransfer"
      :mainMessage="$t('promotion.tradingBonus.endedTip')"
    ></CheckDialog>
    <CampaignWithdrawalModal
      :campaignWithdrawalModalVisible.sync="campaignWithdrawalModalVisible"
      @proceed="withdrawConfirmProceed"
      :isNotice="false"
    >
    </CampaignWithdrawalModal>
  </DefaultTemplate>
</template>

<script>
import Result from '@/components/Result';
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue';
import NumericInput from '@/components/NumericInput';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import {
  apiGetTransferData_cp,
  apiApplyTransfer_cp,
  apiGetTransferableCurrency,
  apiGetTransferToRate,
  apiTransferPermission,
  apiCampaignInfo,
  apiCheckInfoNoBonusCampaign
} from '@/resource';
import floatObj from '@/util/floatObj';
import splitThousands from '@/util/splitThousands';
import checkPositionMixin from '@/mixins/page/tools/checkPosition';
import promotionTradingBonusMixin from '@/mixins/promotion/tradingBonus';
import blackList from '@/mixins/blackList';
import mixin from '@/mixins';
import CheckDialog from '@/components/promotion/common/CheckDialog.vue';
import { getObjectValue } from '@/util/common';
import { mapState } from 'vuex';
import CampaignWithdrawalModal from '@/components/withdraw/CampaignWithdrawalModal.vue';
import TrophyIcon from '@/components/icon/Trophy.vue';

export default {
  name: 'transferFunds',
  components: {
    Result,
    InteractiveDialog,
    NumericInput,
    DefaultTemplate,
    CheckDialog,
    CampaignWithdrawalModal,
    TrophyIcon
  },
  mixins: [checkPositionMixin, promotionTradingBonusMixin, blackList, mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < 0) {
        callback(new Error(this.$t('common.formValidation.amt0')));
      } else if (parseFloat(value) > this.availableBalance) {
        callback(new Error(this.$t('transfer.transferAmountIsGreaterThanAvailableBalance')));
      } else {
        callback();
      }
    };
    return {
      rate: 0,
      amountTo: '',
      currentCurrency: '',
      availableBalance: 0,
      flag: true,
      dialog: {
        show: false,
        text: '',
        type: ''
      },
      transferableCurrencyList: [],
      formAccountOptions: [],
      toAccountOptions: [],
      selected: {
        fromAccount: '',
        toAccount: '',
        currentCurrency: ''
      },
      ruleForm: {
        amount: '',
        fromAccount: '',
        toAccount: ''
      },
      rules: {
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        fromAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change'
          }
        ],
        toAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change'
          }
        ]
      },
      campaignWithdrawalModalVisible: false,
      clickedProceed: false
    };
  },
  computed: {
    ...mapState('promotion/tradingContest', ['tradingContestAcc', 'tradingContestWithdrawRestrict']),
    tradingContestAccNo() {
      return this.tradingContestAcc ? this.tradingContestAcc : 'NA';
    },
    toShowCampaignWithdrawalModal() {
      let fromAcc = this.selected.fromAccount.split(' - ')[0];
      if (
        this.tradingContestAccNo == fromAcc &&
        this.clickedProceed == false &&
        this.tradingContestWithdrawRestrict == true
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    formAccountOptions(options) {
      if (options.length > 0) {
        const firstAccountIndex = options.findIndex(account => Number(account.balance) >= 0);
        if (firstAccountIndex !== -1) {
          this.selected.fromAccount =
            this.formAccountOptions[firstAccountIndex].login +
            ' - ' +
            this.formAccountOptions[firstAccountIndex].currency;
        }
      }
    },
    'selected.fromAccount'(value) {
      this.clickedProceed = false;
      this.selected.toAccount = '';
      [this.ruleForm.fromAccount, this.selected.currentCurrency] = [...value.split(' - ')];
      this.getTransferableCurrency(this.selected.currentCurrency);
    },
    'selected.toAccount'(value) {
      [this.ruleForm.toAccount, this.currentCurrency] = [...value.split(' - ')];
      if (this.currentCurrency !== undefined && this.currentCurrency !== null) this.getTransferToRate();
    },
    'ruleForm.amount'(value) {
      this.amountTo = this.$options.filters.numberWithCommas(floatObj.multiply(value, this.rate));
    }
  },
  methods: {
    setBalance(val) {
      const [availableBalance] = this.formAccountOptions.filter(item => {
        return item.login === parseInt(val);
      });
      if (availableBalance == undefined) {
        return;
      }
      this.availableBalance = availableBalance.balance;
    },
    getToAccounts() {
      this.toAccountOptions = this.formAccountOptions.filter(t => {
        return this.transferableCurrencyList.includes(t.currency) && t.login != this.ruleForm.fromAccount;
      });
    },
    getCurrencySymbol(item) {
      return (
        item.login +
        ' - ' +
        this.$options.filters.currencyToSymbol(item.currency) +
        splitThousands(item.balance, 2) +
        ' ' +
        item.currency
      );
    },
    getTransferableCurrency(currency) {
      this.transferableCurrencyList = [];
      apiGetTransferableCurrency(currency)
        .then(res => {
          if (res.data.code === 0) {
            this.transferableCurrencyList = res.data.data;
            this.setBalance(this.ruleForm.fromAccount);
            this.getToAccounts();
          }
        })
        .catch(err => {
          this.$message({ message: 'Get Transferable Currency Failed.', type: 'error' });
        });
    },
    getTransferToRate() {
      apiGetTransferToRate({
        formCurrency: this.selected.currentCurrency,
        toCurrency: this.currentCurrency
      })
        .then(res => {
          this.rate = res.data.data;
          this.amountTo = this.$options.filters.numberWithCommas(floatObj.multiply(this.ruleForm.amount, this.rate));
        })
        .catch(err => {
          this.$message({ message: 'Get Conversion Rate Failed.', type: 'error' });
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (this.toShowCampaignWithdrawalModal) {
          this.campaignWithdrawalModalVisible = true;
          return;
        }
        if (valid) this.checkPrerequisiteAndSubmit();
        else return false;
      });
    },
    checkPrerequisiteAndSubmit() {
      if (!this.checkTransferBlacklist()) {
        this.transferErrMsg();
      } else {
        apiTransferPermission({ account: this.ruleForm.fromAccount })
          .then(result => {
            if (result.data.data === 'BLACKLISTED') {
              this.dialog = {
                show: true,
                text: '',
                type: 'BLACKLISTED'
              };
            } else if (result.data.data === 'CREDITEXIST') {
              this.dialog = {
                show: true,
                text: this.$t('transfer.creditWarn'),
                type: 'CREDITEXIST'
              };
            } else if (result.data.data === 'PERMITTED') {
              this.checkPosition(this.ruleForm.fromAccount, this.ruleForm.amount, 'transferFunds', () => {
                this.checkActiveCampaign(this.ruleForm.fromAccount, this.ruleForm.toAccount).then(result => {
                  if (result) this.applyTransfer();
                });
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    checkActiveCampaign(from, to) {
      return Promise.all([this.getActiveTradingBonusAccount(), apiCheckInfoNoBonusCampaign()]).then(
        ([activeAccount, ndbData]) => {
          // check Trading Bonus
          if (`${activeAccount}` === `${from}` || `${activeAccount}` === `${to}`) {
            this.tradingBonus.checkVisible = true;
            return false;
          }

          // check No Deposit Bonus
          const activeNDBaccount = getObjectValue(ndbData, 'data.data.mt4AccountContent.mt4_account');

          if (`${activeNDBaccount}` === `${from}` || `${activeNDBaccount}` === `${to}`) {
            this.$message({ message: this.$t('promotion.notification.ndb.cantInerTransfer'), type: 'error' });
            return false;
          }
          return true;
        }
      );
    },
    async checkTransferBlacklist() {
      if (this.$route.params.passBlacklist) return true;
      else
        await this.getTransferBlacklist().then(resp => {
          return resp;
        });
    },
    applyTransfer() {
      apiApplyTransfer_cp({ ...this.ruleForm }, this.token)
        .then(result => {
          if (result.data.code == 0) this.flag = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchTradingAccount() {
      apiGetTransferData_cp()
        .then(resp => {
          if (resp.data.code === 0) this.formAccountOptions = resp.data.data.logins;
        })
        .catch(err => {
          return this.$message({ message: 'Fetch trading accounts failed.', type: 'error' });
        });
    },
    withdrawConfirmProceed() {
      this.clickedProceed = true;
      this.submitForm('ruleForm');
    }
  },
  mounted() {
    this.fetchTradingAccount();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transferFunds.scss';
</style>
