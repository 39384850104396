
function splitThousands(num, fixed) {
  var regNumber = /^(\-)?\d+(\.\d+)?$/;
  if(!regNumber.test(num)){
    return "0";
  }
  var reg = /\B(?=(\d{3})+$)/g;
  num = num.toString().split(".");
  fixed = fixed == undefined ? 2 : fixed;

  num[0] = num[0].replace(reg, ",");
  num[1] = num[1] ? num[1].substr(0, fixed) : "00";

  return num.join(".");
}
export default splitThousands;
