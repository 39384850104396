<template>
  <el-dialog
    :visible.sync="visible"
    top="0"
    :width="'500px'"
    :title="isNotice ? $t('promotion.tradingContest.notice') : $t('promotion.tradingContest.confirmation')"
    class="campaign_withdrawal_modal"
  >
    <div>
      <div class="content mb-10">
        <div class="content_desc">
          <i18n path="promotion.tradingContest.withdrawDesc1" tag="span">
            <template v-slot:tradingContest>
              <span class="font_bold">{{ campaignName }}</span>
            </template>
          </i18n>
          <span v-if="!isNotice">&nbsp;{{ $t('promotion.tradingContest.withdrawDesc2') }}</span>
          <span>&nbsp;{{ $t('promotion.tradingContest.withdrawDesc3') }}</span>
        </div>
      </div>
      <div class="btn_box">
        <el-button class="secondary" @click="handleClose">{{ $t('promotion.tradingContest.back') }}</el-button>
        <el-button class="primary" @click="handleConfirm">{{ $t('promotion.tradingContest.confirm') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'CampaignWithdrawalModal',
  props: {
    campaignWithdrawalModalVisible: Boolean,
    isNotice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    campaignName() {
      let tradingContest = this.$store.state.promotion.tradingContest.tradingContestInfo;
      return tradingContest && tradingContest.clientPortalSettings
        ? tradingContest.clientPortalSettings.pageTitle
        : null;
    }
  },
  watch: {
    campaignWithdrawalModalVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:campaignWithdrawalModalVisible', bool);
    }
  },
  methods: {
    handleClose() {
      this.visible = !this.visible;
    },
    handleConfirm() {
      this.$emit('proceed');
      this.handleClose();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/tradingContest.scss';

/deep/ {
  .el-dialog {
    width: 480px;
    max-width: 98%;
    max-height: 90vh;
    overflow: auto !important;
  }

  .el-dialog__header {
    padding: 40px 40px 5px 35px;
  }

  .el-dialog__body {
    padding: 0 40px 40px;
  }

  .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
  }

  .el-dialog__headerbtn {
    display: none;
  }
}
.content_desc {
  margin: 20px 0 30px 0;
}
</style>
